<template>
  <div>
    Redirecting to v1 ...
  </div>

</template>

<script>
import { defineComponent } from '@vue/composition-api';


export default defineComponent({
  name: "ToV1Component",
  components: {},
  mounted: function(){
    localStorage.removeItem('kiosk-version')
    window.location.href = process.env.VUE_APP_URL
  }
})
</script>

<style scoped>

</style>